<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_config.sub_cat_name')"
              label-for="sub_group_name"
            >
            <b-form-input
              id="name"
              v-model="search.sub_group_name"
              placeholder=""
              ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_config.warehouse_sub_group_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-row v-show="dataCheck">
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                  <template v-slot:cell(index)="data">
                    {{ $n(data.index + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(sub_group_name)="data">
                    {{ data.item.sub_group_name }}
                  </template>
                  <template v-slot:cell(sub_group_name_bn)="data">
                    {{ data.item.sub_group_name_bn }}
                  </template>
                  <template v-slot:cell(status)="data">
                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="pagination.totalRows"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="testClick"
                  />
              </b-col>
            </b-row>
            <b-row v-show="DataNotFound">
             <div class="active text-danger">Data Not Found</div>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="testId"/>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Form from './Form'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { SubGroupList, SubGroupToggleStatus } from '../../api/routes'

export default {
  components: {
    Form
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        sub_group_name: ''
      },
      dataCheck: false,
      DataNotFound: false,
      testId: 0
    }
  },
  computed: {
    formTitle () {
      return (this.testId === 0) ? this.$t('warehouse_config.warehouse_sub_group_entry') : this.$t('warehouse_config.warehouse_sub_group_modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
            if (this.listReload || this.loading) {
                return true
            } else {
                return false
            }
        },
    listData () {
      return this.$store.state.WareHouseConfig.subgroups
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.sub_cat_name'), class: 'text-left' },
          // { label: this.$t('warehouse_config.sub_category_name_bn'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'sub_group_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'sub_group_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
   watch: {
        loadingState: function (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
  async created () {
    this.mixinThis = this
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        sub_group_name: '',
        sub_group_name_bn: '',
        editable: false
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    async searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
     toggleStatus (item) {
            RestApi.deleteData(warehouseServiceBaseUrl, `${SubGroupToggleStatus}/${item.id}`).then(response => {
                if (response.success) {
                    this.$store.dispatch('WareHouseConfig/updateWarehouseTypeStatus', item)
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data Updated Successfully',
                        color: '#D6E09B'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Operation failed! Please, try again.'
                    })
                }
            })
        },
    remove (item) {
       this.$swal({
                title: this.$t('globalTrans.statusChangeMsg'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.toggleStatus(item)
                }
            })
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const result = await RestApi.getData(warehouseServiceBaseUrl, SubGroupList, params)
      if (result.data.data > 0) {
        this.dataCheck = true
        this.$store.dispatch('WareHouseConfig/addSubGroupList', result.data.data)
        this.paginationData(result.data)
      } else {
        this.DataNotFound = true
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
